;(function ( $ ) {
	var dragStartStamp = 0;
	var dragSpeedStartStamp = 0;
	var dragSpeedStartOffset = 0;
	var dragStartOffset = 0;
	var dragscroll = true;
	var snapTo = false;
	var initBlocksTimer;

	var methods = {
	    init : function( options ) {
	      // THIS
	    },
	    setScroll : function( dat ) {
			methods.gotoPage(this)
			dragscroll = false;
	    },
	    gotoPage : function( num ) {
			
	    }
	};

	$.fn.scrollVertical = function(method) {
        return this.each( function(){
			var $this = $(this);
			var $inner_page = $this.find('.inner-page');
			var $blocks = $(this).find('.blocks');
			var dossier_id = $(this).parent().data('dossier-id');
			var first_page = $this.find(".page:first");
			var offset = $blocks.data('offset');
			var busy = false;

			if( ! window_height) {
				var window_height = $(window).height();
			}

   			$this.scrollStopped(function(){
   				if(snapTo) {
					//scrollToNearestPage();
   				}
				initBlocks();
			  	busy = false;
			});

			$this.scroll(function(){
				if((($this.scrollTop() + window_height) >  $inner_page.height())  && ! busy) {
					offset = $blocks.data('offset');
					busy = true;
					$.post('/website/get_dossier_blocks', {dossier_id: dossier_id, offset:offset, limit: 6}, function(data) {
						$blocks.append(data);
						busy = false;
						offset = offset + 6;
						$blocks.data('offset',offset);
						clearTimeout('initBlocksTimer');
						initBlocksTimer = setTimeout(function(){
							initBlocks();
						}, 300);
					});		
				}
			});

			if (Modernizr.touch) {
				$inner_page.addClass('dragwrapper');
				$inner_page.addClass('do_touch');
				var $dragwrapper = $this.find('.dragwrapper');
				initDrag();
			}

			function scrollToNearestPage() {
				//alert(dragscroll);
				var closest = 100000;
				var topage = '';
				var pages = $this.find(".block");
				pages.each(function() {
				    var page = $(this);
				    if(page.position().top){
					    var position = page.position().top;
					    if(Math.abs(position) < closest) {
					    	closest = Math.abs(position);
					    	topage = page;
						}				    	
				    }
				});
				scrollToPage(topage);
			}

			function scrollPageTo(offset, speed, easing){
				$('.iosslider').iosSlider('lock');
			    $this.stop().animate({
			        scrollTop: -offset
			    }, speed, easing, function(){
					//$('.iosslider').iosSlider('unlock');
			    	$this.stop()
				});
			}

			function scrollToPage(page) {
				//if(page.offset().top != 0) {
					var offset = -$this.scrollTop() - page.position().top + 25;
					snapTo = false;
					scrollPageTo(offset, 600, "easeOutCirc");
				//}
			}

			function initDrag() {
				$dragwrapper.drag(function( ev, dd ){
					$('.iosslider').iosSlider('lock');
					if(Math.abs(dd.deltaY) > 5 || Math.abs(dd.deltaY) > Math.abs(dd.deltaX)) {
						$('.iosslider').iosSlider('lock');
					}
					//if(Math.abs(dd.deltaY) > Math.abs(dd.deltaX)) {
						$this.scrollTop( - (dd.offsetY - 119));
					//}
					if((ev.timeStamp - dragSpeedStartStamp) > 300)  {
						dragSpeedStartStamp = ev.timeStamp;
						dragSpeedStartOffset = $this.scrollTop();
					}
				}).drag('start', function( ev, dd ){
					$this.stop();
					dragStartStamp = ev.timeStamp;
					dragSpeedStartStamp  = ev.timeStamp;
					dragStartOffset = $this.scrollTop();
					dragSpeedStartOffset = $this.scrollTop();
					if(Math.abs(dd.deltaY) > Math.abs(dd.deltaX)) {
						$this.scrollTop( - (dd.offsetY-119));
					}
				}).drag('end', function( ev, dd ){
					//$('.iosslider').iosSlider('unlock');
					var deltaTime = dragStartStamp - ev.timeStamp;
					var deltaSpeedTime = dragSpeedStartStamp - ev.timeStamp;
					var delta = $this.scrollTop() - dd.deltaY;
					var deltaspeed = $this.scrollTop() - dragSpeedStartOffset;
					var swipespeed =  deltaspeed/deltaSpeedTime;
					if(Math.abs(swipespeed) > 0.3) {
						snapTo = true;
						var offset = - $this.scrollTop() + (swipespeed * 350);
						scrollPageTo(offset, 2100, "easeOutCubic");
					}

				}).bind('touchstart', function(){
					$this.stop();
				});
			}
			if ( methods[method] ) {
				return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
			} else if ( typeof method === 'object' || ! method ) {
				return methods.init.apply( this, arguments );
			} else {
				$.error( 'Method does not exist' );
			}
		});
    };
}( jQuery ));

$.fn.scrollStopped = function(callback) {
	//$('.iosslider').iosSlider('unlock');
    $(this).scroll(function(){
        var self = this, $this = $(self);
        if ($this.data('scrollTimeout')) {
          clearTimeout($this.data('scrollTimeout'));
        }
        $this.data('scrollTimeout', setTimeout(callback,700,self));
    });
};



