$.fn.form = function() {
	return this.each( function(){
		var t;
		var $this = $(this);
		if ( ! $this.data("form")) {
			$this.data("form", true);
			var id = $this.data('id');
			var dossier_id = $this.parent().parent().parent().parent().data('dossier-id');

			var $form = $this.find('.theform');
			var $recipient = $this.find('.recipient');
			var recipient = $recipient.val();
			var subject = $this.find('h2').html();
			var $thanx = $this.find('.thanx');
			var $thanx_close = $this.find('.thanx-close');
			var $inputs = $this.find('.element');
			var $btn_submit = $this.find('.btn-submit');
			var $hide = $this.find('.hide-me');

		 	$form.validationEngine({scroll: false, autoHidePrompt:true, autoHideDelay: 3000});

			$btn_submit.on('click',function(event){ 
				event.preventDefault();
				if ($form.validationEngine('validate') ) {
					sendForm();
				}
			});

			$thanx_close.on('click',function(event){ 
				refreshBlock(id, dossier_id);
				// event.preventDefault();
				// $thanx.slideUp();
				// $thanx_close.fadeOut();
				// $hide.show();
			});

			function sendForm(){
				var json = [];
				$inputs.each(function(){
					var label = $(this).prev().html();
					var value = $(this).val();
					var $element = $(this);
					if( $element.hasClass('choices') ) {
						var choices = $(this).find('.choice');
						choices.each(function() {
							if( $(this).prop("checked") ){
								if( $element.hasClass('multiple') ) {
									value += $(this).next().html() + " - ";
								}
								else {									
									value = $(this).next().html();
								}								
							}
						});
					}
					json.push(
						{'label': label, 'value': value}
					);
				});
				$.post('/user/send_form', {from: recipient, subject: subject, json: JSON.stringify(json)}, function(data) {
					$thanx.slideDown();
					$thanx_close.fadeIn();
					$hide.hide();
				});
			}
		}
	});	
};