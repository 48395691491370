$.fn.block = function() {
	return this.each( function(){
		var $this = $(this);
		 if ( ! $this.data("block")) {
			$this.data("block",true);

			var save_timer;
			//FIELDS
			var id = $this.data('id');
			var dossier_id = $this.parent().parent().parent().parent().data('dossier-id');
			var $color = $this.data('type');
			
			var $media_wrapper = $this.find('.media-wrapper');
			var $image = $this.find('.image');
			var $images = $this.find('.images');

			var $more_also = $this.find('.more-also');
			var $more = $this.find('.more');
			var $also = $this.find('.also');
			var $video = $this.find('.video');
			var $audio = $this.find('.audio');
			var $btn_toggle_audio = $this.find('.toggle-audio');

			var $right = $this.find('.arrow-right');
			var $left = $this.find('.arrow-left');
			var $caption = $this.find('.caption');
			var $other_dossiers = $this.find('.other-dossiers');
			var $text_large = $this.find('.text-large-wrapper');
			
			var $column_one = $this.find('.column-one');
			var $column_two = $this.find('.column-two');
			var $column_three = $this.find('.column-three');
			
			var $share = $this.find('.share');
			var $social_icons = $this.find('.social-icons');
			var $social_icons_a = $social_icons.find('a');

			$social_icons.hide();
			setImage();
			setAudio();
			setVideo();
			
			$more.on('click', function(event){
				event.preventDefault();
				toggle();
			});
			
			$also.on('click', function(event){
				event.preventDefault();
				$other_dossiers.slideToggle();
			});

			$social_icons_a.on('click', function(event){
				event.stopPropagation();
			});

			// $this.on('mouseenter', function() {
			// 	$caption.stop().delay(900).slideUp();
			// }).on('mouseleave', function() {
			// 	$caption.stop().slideDown();
			// });

			$share.on('click', function(event){
				event.preventDefault();
				event.stopPropagation();
				if($social_icons.css('display') == 'none'){
					showSocial()
				}
				else {
					//from global scope
					hideSocial();
				}
			});

			$image.on('click',function(){
				var title = $caption.data('title');
				if($this.hasClass('is_3d')) {
					showDetail(id, title ,'3d', $color);
				}
				else if($images.data('value') != "" && jQuery.parseJSON($images.data('value')).length > 1){
					//from global scope
					showDetail($images.data('value'), title ,'slideshow', $color);
				}
				else {
					//from global scope
					showDetail($image.data('value'), title, 'image',$color);					
				}
			});
			
			$images.on('click',function(){
				//from global scope
				showDetail($images.data('value'), 'slideshow');
			});

			$btn_toggle_audio.on('click', function(){
				if(! $(this).hasClass('playing')){
					$(this).addClass('playing');
					$audio.jPlayer("play");
				}
				else {
					$(this).removeClass('playing');
					$audio.jPlayer("pause");					
				}
			});
			
			$right.on('click',function(){
				$(this).fadeOut();
				$left.fadeIn();	
				$column_one.hide();
				if($this.hasClass('media-small')) {
					$media_wrapper.hide();
				}
				$column_three.show();
			});
			
			$left.on('click',function(){
				$(this).fadeOut();
				$right.fadeIn();
				$media_wrapper.show();
				$column_one.show();
				$column_three.hide();
			});

			//SET STUFF
			function setImage() {
				if($image.data('value') && ! $this.hasClass('adlib')){
					var img_name = "/media/images/medium/"+$image.data('value');
					$image.backstretch(img_name);
				}
				if($images.data('value') != "" && ($this.hasClass('image') || $this.hasClass('image_text')  || $this.hasClass('adlib')) && $images.data('value')){
					if(jQuery.parseJSON($images.data('value')).length > 1){
						$image.append("<img src='/assets/img/icon_slideshow.png' style='position:absolute;left:50%;top:40%;width:80px;height:80px;margin-left:-40px;margin-top:-40px;'>");
					}
				}
				if($this.hasClass('is_3d')){
					$image.append("<img src='/assets/img/icon-3d.png' style='position:absolute;left:50%;top:50%;width:80px;height:80px;margin-left:-40px;margin-top:-40px;'>");
				}
			}

			function showSocial(){
				$social_icons.slideDown();
				$this.nextAll().stop().animate({opacity: 0.2});
				$this.prevAll().stop().animate({opacity: 0.2});					
			}
 
			function toggle() {
				if($more.html() != 'Terug') {
					$text_large.slideDown(200);
					$text_large.fadeIn();
					if($more.hasClass('all')) {
						$this.removeClass('small');					
						$this.removeClass('medium');					
						$this.addClass('large');					
					}
					if($more.hasClass('two')) {
						$this.removeClass('small');					
						$this.addClass('medium');
					}
					$more.html('Terug');
				} 
				else {
					var $page = $this.closest('.page');
					var ypos = -$page.scrollTop() - $this.position().top + 155;
					refreshBlock(id, dossier_id);

					$page.stop().animate({
					    scrollTop: -ypos
					}, 500);					
				}
			}
			
			function setVideo() {
				if($video.data('value')){
					var video_url= "/media/videos/"+$video.data('value');
					jwplayer("video-"+id).setup({
						file: video_url,
						autostart: false,
						controls: true,
						width: '100%',
						height: '100%',
					});
				}
			}
			
			function setAudio() {
				if($audio.data('value')){
					$audio.jPlayer({
						ready: function () {
						},
						timeupdate: function(event) {
						},
						play: function(event) {
						},
						pause: function(event) {
						},
						ended: function(event) {
							$btn_toggle_audio.removeClass('playing');							
						},
						swfPath: "/assets/js/lib",
						wmode: "window"
					});
					var audio_url= "/media/audio/"+$audio.data('value');
				
					$audio.jPlayer("setMedia", {
						mp3: audio_url
					});
				}
			}
		}
	});	
};
