$.fn.poll = function() {
	return this.each( function(){
		var t;
		var done = false;
		var $this = $(this);
		if ( ! $this.data("poll")) {
			$this.data("poll", true);
			var id = $this.data('id');

			var $recipient = $this.find('.recipient');
			var recipient = $recipient.val();
			var subject = $this.find('h2').html();
			var $thanx = $this.find('.thanx');
			var $poll_choices = $(this).find('.choice');
			var $btn_submit = $this.find('.btn-submit');
			var $hide = $this.find('.hide-me');
			var $bars = $this.find('.bar');
			$bars.parent().hide();

			$poll_choices.each(function(){
				$(this).on('change',function(event){ 
					event.preventDefault();
					if(!done){
						var question = $(this).next().html();
						var answer = $(this).parent().prev().html();
						var choice_id = $(this).attr('id');
						done = true;
						sendForm(question, answer, choice_id);
					}
				});
			});

			function sendForm(question, answer, choice_id){
				$.post('/user/send_poll', {block_id: id, question: question, answer: answer, choice_id: choice_id}, function(data) {
					console.log(data);
					setResult(data);
				});
			}
		}

		function setResult(data){
			$poll_choices.attr('disabled', true);
			var $result = jQuery.parseJSON(data);
			$bars.css({'width' : '1px'});
			$bars.parent().fadeIn();
			var width = $bars.parent().width();
			var total = 0;
			$.each($result, function (i, v) {
				total = total + parseInt(v.score);
			});

			$.each($result, function (i, v) {
				var perc = (v.score * 100)/total;
				var visualperc = (v.score * width)/total;
				$("."+v.choice_id+"-"+id).find(".bar").animate({'width' : visualperc});
				$("."+v.choice_id+"-"+id).before('<span style="float:right;color:#5c880f;"><strong>'+Math.round(perc)+'%</strong></span>')
			});


		}
	});	
};