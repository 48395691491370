var window_height;
var busy = false;
var current_block_id = 0;
var react_on_block_id = 0;
var backstretch_timer;
var $slides;
var mobile_width = 650;
var active_slide_no = -1;

$(function() {
	if(! $.cookie('firstvisit') && ! Modernizr.touch) { 
	    $.cookie('firstvisit', '1');
		setTimeout(function(){
			showPersonalize();
		},2000);
	}
	backStretch();
	$(window).resize(function() {
		window_height = $(window).height();
		window_width = $(window).width();
		if( ! Modernizr.csscalc) {
			$(".slider-wrapper").width(window_width - 155);
			$("#next-slide").addClass('fixme');
			$("#overlay-right").hide();
		}
		clearTimeout(backstretch_timer);
		backstretch_timer = setTimeout(function(){
			backStretch();
		}, 300);
	});

	window_height = $(window).height();
	window_width = $(window).width();
	if( ! Modernizr.csscalc) {
		$(".slider-wrapper").width(window_width - 155);
		$("#next-slide").addClass('fixme');
		$("#overlay-right").hide();
	}
	initBlocks();
	hidePersonalize();

	$('.iosslider').fadeIn();

	setTimeout(function(){
		setSlider();
		getContent(0);
	});
	
	if(window_width < 550) {
		if(! $.cookie('first-mobile-visit')) { 
			$("body").append("<div class='alert-mobile'><span class='alert-mobile-close'>x</span>Welkom! Je kunt deze website bekijken op je mobiel, maar het gebruikersgemak is groter als je ons bezoekt vanaf pc, tablet of laptop.</div>");
		    $.cookie('first-mobile-visit', '1');
		    $(".alert-mobile").on('click', function(){
		    	$(this).slideUp();
		    });
		}
		setTimeout(function(){	
			$(".phone").trigger('click');
		},1500);
	}

 	$(document).keyup(function(e){
		if (e.keyCode == 27) {
			hideAll();
		}
	});	

 	$(document).on('click',function(e){
		hideSocial();	
 	});
 	
	$("#personalize").find('.close').hide();

	$(".btn-login").on('click',function(event){
		event.preventDefault();
		showRegister();
	});

	$("#personalize").find('.open').on('click',function(event){
		event.preventDefault();
		showPersonalize();
	});

	$("#personalize").find('.close').on('click',function(event){
		event.preventDefault();
		hidePersonalize();
	});

	$("#global-search").autocomplete({
		serviceUrl: '/website/keywords',
		onSelect: function (suggestion) {
			document.location.href="/zoek?q="+suggestion.value;
		},
	});

	$(".page").scrollVertical();

	$("#global-search").keyup(function(e){
		if (e.keyCode == 13) {
			document.location = "/zoek?q="+$(this).val();
		}
	});	

	$("#next-slide").on('click', function(e) {
		e.preventDefault();
	});

	$("#prev-slide").on('click', function(e) {
		e.preventDefault();
	});

	$(".submit-filter-educatie").on('click', function(e){
		e.preventDefault();
		$("#filter-educatie").submit();
	});
	
    History.Adapter.bind(window,'statechange',function(){ 
        var State = History.getState();
    	var slide_no = State.data.state;
    	if(active_slide_no !=  slide_no) {
    		$(".iosslider").iosSlider('goToSlide', slide_no+1);
    	}
    });
	// setInterval(function(){
	// 	initBlocks();
	// }, 1000);
});

function backStretch() {
	$(".dossier-slide").each( function(){
		if($(this).data('dossier-image') != "" ){
			var img_name = "/media/images/pages/large/"+$(this).data('dossier-image');
			//console.log('123');
			$(this).backstretch(img_name);
		}
	});
	
	$(".overview-slide").backstretch("/media/images/toon_alles.jpg");
	
	// $(".block-slide").each( function(){
	// 	if($(this).data('block-image') != "" ){
	// 		var img_name = "/media/images/large/"+$(this).data('block-image');
	// 		$(this).backstretch(img_name);
	// 	}
	// });

	// $(".block").find(".image").each( function(){
	// 	if($(this).data('value') && ! $(this).parent().hasClass('media-adlib')){
	// 		var img_name = "/media/images/medium/"+$(this).data('value');
	// 		$(this).backstretch(img_name);
	// 	}
	// });

	$(".ankeiler").each( function(){
		if($(this).data('background') != "" ){
			var img_name = "/media/images/pages/small/"+$(this).data('background');
			$(this).backstretch(img_name);
		}
	});
}

function showRegister(){
	hidePersonalize();
	//if($("#login-register").html() == "") {
		$("#login-register").load('/user/login_register', function(){
		 	$(".validate-form").validationEngine({scroll: false, autoHidePrompt:true, autoHideDelay: 3000});
		 	$(".register-close, #overlay").on('click',function(){
		 		hideRegister();
		 	});
		 	$("#btn-register").on('click', function(event){
		 		event.preventDefault();
		 		if ($("#register-form").validationEngine('validate') ) {
		 			registerUser();
		 		}
		 	});
		 	$("#btn-login").on('click', function(event){
		 		event.preventDefault();
		 		if ($("#login-form").validationEngine('validate') ) {
		 			loginUser();
		 		}
		 	});
		 	$(".reset_password").on('click', function(event){
		 		event.preventDefault();
				hideRegisterForms();
				showResetPassword();
		 	});
			$("#btn-reset-password").on('click',function(event){
				event.preventDefault();
				var email = $("#reset-email").val();
				$.post('/user/reset_password', {email:email}, function(data) {
					if(data == "unknown") {
						alert('deze gebruiker is niet bekend');
					}
					else {			
						$("#reset-password-thanx").slideDown();
						$("#btn-reset-password").hide();
						$("#reset-email").hide();
					}
				});		
			});
			$("#header-login").on('click',function(event){
				event.preventDefault();
				$("#fields-login").slideDown();
			});
			$("#header-register").on('click',function(event){
				event.preventDefault();
				$("#fields-register").slideDown();
				$(".submit").show();
			});
		});
	//}
	$("#login-register").show();
	var top = 70;
	if(window_width < mobile_width) {
		top = 0;
	}
	$("#login-register").animate({top: top}, 600);
	$("#overlay").fadeIn(600);
}

function hideRegister(){
	$("#login-register").animate({top: '-1200px'}, 600);
	$("#login-register").fadeOut(600);
	$("#overlay").fadeOut(600);
}

function showReact(block_id){
	current_block_id = block_id;
	react_on_block_id = 0;
	hidePersonalize();
	hideRegister();
	//if($("#react").html() == "") {
		$("#react").load('/user/react', function(){
		 	$(".validate-form").validationEngine({scroll: false, autoHidePrompt:true, autoHideDelay: 3000});
		 	$(".react-close, #overlay").on('click',function(){
		 		hideReact();
		 	});
		 	$("#btn-react").on('click', function(event){
		 		event.preventDefault();
		 		if ($("#react-form").validationEngine('validate') ) {
		 			var comment = $("#react-text").val();
		 			var user_id = $("#react_user_id").val();
					$.post('/user/react', {user_id: user_id, block_id: current_block_id, comment: comment}, function(data) {
						$("#react-form").slideUp();
						$("#react-thanx").slideDown();

					});
		 		}
		 	});
		});
	//}
	$("#react").stop().show();
	var top = 70;
	if(window_width < mobile_width) {
		top = 0;
	}	
	$("#react").animate({top: top}, 600);
	$("#overlay").fadeIn(600);
}

function hideReact(){
	$("#react").animate({top: '-1200px'}, 600);
	$("#react").fadeOut(600);
	$("#overlay").fadeOut(600);
}

function showDetail(content, title, type, color){
	hidePersonalize();
	$.post('/website/detail', {content: content, title: title, type: type}, function(data) {
		$("#detail").html(data);
		$("#detail").removeClass();
		if(type == '3d') {
			$("#detail").addClass('three-d');			
		}
		$("#detail").addClass(color);
	 	$(".detail-close, #overlay").on('click',function(){
	 		hideDetail();
	 	});
 		if(window_height > window_width){
 			$(".testme").width(window_width - 120);
 			$(".testme").css({'height':'auto'});
 		}
	});
	$("#detail").stop();
	$("#detail").show();
	var top = 70;
	if(window_width < mobile_width) {
		top = 0;
	}
	$("#detail").animate({top: top}, 600);
	$("#overlay").fadeIn(600);
}

function hideDetail(){
	$("#detail").animate({top: '-1200px'}, 600);
	$("#overlay").fadeOut(600);
}

function showShop(uri){
	hidePersonalize();
	$.post('/website/webshop', {uri: uri}, function(data) {
		$("#webshop").html(data);
	 	$(".webshop-close, #overlay").on('click',function(){
	 		hideShop();
	 	});
	});
	$("#webshop").show();
	var top = 70;
	if(window_width < mobile_width){
		top = 0;
	}
	$("#webshop").animate({top: top}, 600);
	$("#overlay").fadeIn(600);
}

function hideShop(){
	$("#webshop").animate({top: '-1200px'}, 600);
	$("#webshop").fadeOut(600);
	$("#overlay").fadeOut(600);
}

function showPersonalize(){
	$("#personalize").find('.panel').stop().slideDown();
	$("#personalize").find('.open').hide();
	$("#personalize").find('.close').show();
}

function hidePersonalize(){
	$("#personalize").find('.panel').slideUp();
	$("#personalize").find('.open').show();
	$("#personalize").find('.close').hide();
}

function setSlider(){
	$slides = $(".slide");
	if($slides.length == 1 || $(".slider").hasClass('search')) {
		$(".nav-stroke").addClass('inactive');
		$(".slide").addClass('single');
	}
	else {
		$('.iosslider').iosSlider({
			snapToChildren: true,
			scrollbarHide: true,
			responsiveSlideContainer: true,
			responsiveSlides: true,
			infiniteSlider: true,
			desktopClickDrag: false,
			keyboardControls: true,
			navNextSelector: $('#next-slide'),
			navPrevSelector: $('#prev-slide'),
			startAtSlide: 1,
			onSlideComplete: resetContent,
		});
		$('.iosslider').iosSlider('lock');
	}	
}

function resetContent(args){
	var active_no = args.currentSlideNumber-1;
	active_slide_no = active_no;
	getContent(active_no);
	History.pushState({state:active_no}, null, "?slide="+active_no);
}

function getContent(active_no){
	var prev_no = active_no - 1;
	var next_no = active_no + 1;
	var last_no = $slides.length;
	
	if(prev_no < 0) {
		prev_no = last_no-1;
	}
	if(next_no > last_no-1) {
		next_no = 0;
	}
	$slides.each( function(){
		var dossier_id = $(this).data('dossier-id');
		var current_no = $(this).prevAll().length;
		var $slide = $(this);
		var $backstretch = $slide.children('.backstretch');
		var $blocks = $slide.find('.blocks');
		if(current_no == active_no){
			if($blocks.html() == '') {
				getBlocks(dossier_id, $blocks, 0, 5);
			}
			if($backstretch.length == 0) {
				if($slide.data('dossier-image') != "" ){
					var img_name = "/media/images/pages/large/"+$slide.data('dossier-image');
					$slide.backstretch(img_name);
				}
			}
			if(dossier_id!=0){
				insertPersistentBlocks(dossier_id);				
			}
		}
		else if(current_no == prev_no || current_no == next_no){
			$blocks.html('');
			getBlocks(dossier_id, $blocks, 0, 5);
			if($slide.data('dossier-image') != "" ){
				var img_name = "/media/images/pages/large/"+$slide.data('dossier-image');
				$slide.backstretch(img_name);
			}
		}
		else {
			$backstretch.remove();
			$blocks.html('');
			$blocks.data('offset',0);
		}
	});
}

function insertPersistentBlocks(dossier_id){
	var blockids;
	$(".persistent").remove();
	$.get('/persistent_blocks', function(data) {
		blockids = jQuery.parseJSON(data);
		$.each(blockids, function (i, v) {
			insertBlock(v.block_id, dossier_id);
		});
	});
}

function insertBlock(id, dossier_id){
	$.post('/website/get_block', {id:id, dossier_id:dossier_id}, function(data){
		var first_block = $(".blocks").find('.block:first');
		$(""+data).insertAfter(first_block);
		initBlocks();
	});	
}

function getBlocks(dossier_id, blocks, offset, limit){
	$.post('/website/get_dossier_blocks', {dossier_id: dossier_id, offset:offset, limit: limit}, function(data) {
		blocks.append(data);
		blocks.data('offset', 5);
		setTimeout(function(){
			initBlocks();
		}, 100);
	});		
}

function initBlocks(){
	$(".block").block();
	$(".form").form();
	$(".poll").poll();
	setCommentNav();

	$(".react, .comment").unbind().on('click',function(event){
		event.preventDefault();		
		event.stopPropagation();
		if($(this).data('user-id') != -1) {
			showReact($(this).data('block-id'));
		}
		else {
			react_on_block_id = $(this).data('block-id');
			showRegister();
		}
	});

	$(".shopme").unbind().on('click',function(event){
		event.preventDefault();
		var uri =  $(this).data("uri");
		showShop(uri);
	});
	
	$(".ankeiler").each( function(){
		if($(this).data('background') != "" ){
			var img_name = "/media/images/pages/small/"+$(this).data('background');
			$(this).backstretch(img_name);
		}
	});

	$(".ankeiler, .find-dossier").on('click', function(event){
		event.preventDefault();
		var dossier = $(this).data('dossier-uri');
		var no = $("."+dossier).prevAll().length;
		// if(no > 0 && ! $(".slider").hasClass('search')) {
		// 	$(".iosslider").iosSlider('goToSlide', no+1);
		// }
		// else {
			document.location = "/"+dossier;
		//}
	});

	$(".phone").unbind().on('click', function(event) {
		event.preventDefault();
		if($("header").position().left != 0) {
			$("header").animate({'left': 0}, 400);
			$(this).delay(400).animate({'left': 135}, 1200);
		}
		else {
			$("header").stop().delay(400).animate({'left': -180}, 600);
			$(this).stop().animate({'left': 0}, 100);
		}
	});

	$(".block-editor").parent().addClass('has-editor');
	var incrementalShowTimer = 1;
	
	$(".block-new").each(function(){
		var $block = $(this);
		setTimeout(function(){
			$block.animate({opacity: 1}, 300);
			$block.find('.content').animate({opacity: 1}, 500);
			$block.removeClass('block-new');
		}, incrementalShowTimer);
		incrementalShowTimer = incrementalShowTimer + 200;
	});
	setTimeout(function(){
		busy = false;
	}, incrementalShowTimer);

	if(Modernizr.touch) { 
		$(".enlarge-image").remove();
	}
	$('.iosslider').iosSlider('update');

}

function setCommentNav(){
	$(".prev_comment").on('click', function(event) {
		event.preventDefault();
		var total = $(this).parent().data('total');
		var current = $(this).parent().data('current');
		if(current == 0) {
			prev = 1;
		}
		else {
			prev = current-1;
		}
		$(".comment-wrapper").hide();
		$(".comment-"+prev).stop().show();
		$(this).parent().data('current', prev);
	});

	$(".next_comment").on('click', function(event) {
		event.preventDefault();
		var total = $(this).parent().data('total');
		var current = $(this).parent().data('current');
		if(current == total-1) {
			next = 0;
		}
		else {
			next = current+1;
		}
		$(".comment-wrapper").hide();
		$(".comment-"+next).stop().show();
		$(this).parent().data('current', next);
	});
}

function registerUser(){
	var name = $("#register-form").find("#name").val();
	var why = $("#register-form").find("#why").val();
	var field = $("#register-form").find("#field").val();
	var interest = $("#register-form").find("#interest").val();
	var email = $("#register-form").find("#email").val();
	var password = $("#register-form").find("#password").val();
	var image = $("#user-pic-value").val();
	var newsletter = 0;
	if($("#register-form").find("#newsletter").prop('checked')){
		newsletter = 1;
	}
	var teacher = 0;
	if($("#register-form").find("#teacher").prop('checked')){
		teacher = 1;
	}
	$.post('/user/register', {why: why, field: field, interest: interest, name:name, email: email, password: password, newsletter: newsletter, teacher: teacher, image: image}, function(data) {
		if(data == "known") {
			$("#login-"+data).slideDown();
		}
		else {
			hideRegisterForms();
			if(teacher == 1 ) {
				$(".register-teacher-thanx").slideDown();			
			}
			else {
				$(".register-thanx").slideDown();			
			}
		}
	});
}

function hideRegisterForms(){
	$(".login-pane").slideUp();
	$(".register-pane").slideUp();
	$(".upload-picture").slideUp();
	$(".submit").slideUp();
}

function showResetPassword(){
	$("#reset-password").slideDown();
}

function loginUser(){
	var email = $("#login-form").find("#login-email").val();
	var password = $("#login-form").find("#login-password").val();
	$(".login-alert").hide();
	$.post('/user/login', {name:name, email: email, password: password}, function(data) {
		if(data == "success") {
			hideRegister();
			if(react_on_block_id != 0) {
				//WORKAROUND TO LET BROWSERS KNOW THE USER IS LOGGED IN
				$(".react").data('user-id', 1);
				$(".comment").data('user-id', 1);
				showReact(react_on_block_id);
			}
			else {
				setTimeout(function(){
				 location.reload();
				},600);
			}
		}
		else {
			$("#login-"+data).slideDown();
		}
	});

}

function hideSocial(){
	$(".social-icons").slideUp();
	$(".block").stop().animate({opacity: 1});
	$(".divider").stop().animate({opacity: 1});

}	

function hideAll(){
	hideRegister();
	hideShop();
	hideReact();
	hidePersonalize();
	hideSocial();
	hideDetail();
}


function refreshBlock(id, dossier_id){
	$.post('/website/get_block', {id:id, dossier_id:dossier_id}, function(data){
		$("#block-"+id).replaceWith(data);
		initBlocks();
	});
}


